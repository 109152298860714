export default {
  data() {
    return {
      texts: "",
      title: "",
      descripcion: "",
      api: "",
      vendorOrCustomer: "",
      bLoading: false,
      screenWidth: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.passwordchangeTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit("setPasswordChange", {
        active: false,
        api: "",
      });
    },
    passwordChangeItem() {
      const params = {};

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(this.api, params, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.close();
        })
        .catch((error) => {
          this.close();
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  watch: {
    dialogPasswordChange() {
      if (this.dialogPasswordChange) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.title = this.$store.state.titlePasswordChange;
        // this.descipcion = this.$store.state.descripcionDelete;
        this.descripcion = this.$store.state.descripcionPasswordChange;
        this.api = this.$store.state.apiPasswordChange;
        this.texts = FILE.passwordchangeTexts[this.selectLanguage];
      }
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.passwordchangeTexts[this.selectLanguage];
      }
    },
  },
  computed: {
    dialogPasswordChange() {
      return this.$store.state.dialogPasswordChange;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
};
